export const API_ROOT = process.env.REACT_APP_HOST + process.env.REACT_APP_API_ROOT;

/** FE routes definitions */
export const FE_ROUTES = {
  KEYCLOAK_AUTH: '/',
  HOME: '/home',
  LOGINJUMP: '/login-jump'
};

/** API Path Definitions */
export const API_PATH = {
  GET_MENU: '/menu/menu/',
  GET_HALLOFFAMES: '/hallOfFame/hallOfFames/',
  CREATE_HALLOFFAMER: '/hallOfFame/hallOfFame/',
  DELETE_HALLOFFAMER: '/hallOfFame/hallOfFameById/',
  LIKE_HALLOFFAMER: '/hallOfFame/hallOfFameLike/',
  APPROVE_HALLOFFAMER: '/hallOfFame/approveHallOfFameById/',
  GET_AVALIABLE_JIRA_SPRINT: '/release/sprintSelectList',
  RELEASE_SPRINT: '/release/',
  GET_ALL_CURRENT_RELEASE: '/release/currentRelease',
  PROJECT: '/project/',
  PROJECT_SUMMAR: '/projectSummary/',
  PROJECT_STATUS: '/health/asyncByProjectId/',
  PROJECT_HEALTH: '/health/healthListByProjectStatus',
  ORGANIZATION_ORGANIZATIONS: '/organization/organizations/',
  PROJECT_BASICINFO: '/project/projectBasicInfoR/',
  PROJECT_ALL: '/project/projectByAll/',
  UPDATE_PROJECT_BASICINFO: '/project/projectBasicInfo/',
  GET_ALL_SCRUM_TEAMS: '/scrumTeam/scrumTeamsByAll',
  GET_SCRUM_TEAMS: '/scrumTeam/scrumTeams/',
  UPDATE_SCRUM_TEAMS: '/scrumTeam/',
  PROJECT_MANAGERS: '/projectManager/managersR/',
  GET_DEVMETRICS: '/devMetric/devMetrics/',
  QA_MANAGEMENT_SUMMARY: '/issue/qaManagementSummary',
  CADENCE_CHART_SUMMARY: '/issue/cadenceSummary',
  UpdateStoryCycleTimeThreshold: '/issue/UpdateStoryCycleTimeThreshold',
  UpdateSprintNotes: '/issue/UpdateSprintNotes',
  GET_RULE_VALUE_TARGET: '/issue/ruleValue',
  BASE_QA_CHART_SETTING: '/projectChartFilter',
  CREATE_DELETE_DEVMETRIC: '/devMetric/',
  UPDATE_DEVMETRIC: '/devMetric/devMetric/',
  GET_DEVCHARTS: '/devChart/',
  UPDATE_USER_LOGOUT: '/userManagement/userLogout/',
  GET_USER_INFOS: '/userManagement/userInfos/',
  USER_INFO: '/userManagement/userInfo/',
  USER_ALLOCATION: '/userManagement/userAllocation/',
  GET_USER_ROLES: '/userManagement/Roles/',
  GET_USER_PERMISSIONS: '/userManagement/permissionsByUserId/',
  UPDATE_USER_ROLE_ADD: '/userManagement/userRoleAdd/',
  UPDATE_USER_ROLE_DELETE: '/userManagement/userRoleDelete/',
  USER_TYPE_ALLOCATION: '/userInfo/userTypeAllocation',
  GET_PROJECT_ROLES: '/projectRole/',
  GET_RULE_HISTORY: '/rule/ruleHistoryByProjectId/',
  UPDATE_RULE_HISTORY: '/rule/thresholdValues/',
  UPDATE_PROJECT_HEALTH: '/health/',
  UPDATE_USERINFO_AVATAR: '/userManagement/userInfoAvatar/',
  GET_ALL_DICTTYPE: '/DictType/DictTypes',
  GET_USER_PROJECTS_CURRENT_SPRINT: '/userManagement/userProjectCurrentSprint/',
  MENU_QUICK_ACTION: '/menuQuickAction/',
  DELETE_MENU_QUICK_ACTIONS: '/menuQuickAction/deleteIds/',
  ALL_PROJECT_RISKDAYS: '/risk/projectsRiskDays',
  ALL_PROJECT_CURRENT_MILESTONE: '/milestone/currentMileStones',
  SYNC_USER_FROM_KEYCLOAK: '/userManagement/syncUsersFromKeycloak',
  ALL_MILESTONE_RISKDAYS: '/milestone/mileStoneRiskDays',
  ALL_SPRINT_RISKDAYS: '/release/releaseLongestRiskDays'
};

/** * welcome page */
export const GET_USER_PROJECTS_CURRENT_SPRINT = `${process.env.REACT_APP_API_ROOT}${API_PATH.GET_USER_PROJECTS_CURRENT_SPRINT}`;
export const MENU_QUICK_ACTION = `${process.env.REACT_APP_API_ROOT}${API_PATH.MENU_QUICK_ACTION}`;
export const DELETE_MENU_QUICK_ACTIONS = `${process.env.REACT_APP_API_ROOT}${API_PATH.DELETE_MENU_QUICK_ACTIONS}`;

/** Request api Definitions */
export const GET_MENU = `${process.env.REACT_APP_API_ROOT}${API_PATH.GET_MENU}`;
export const GET_HALLOFFAMES = `${process.env.REACT_APP_API_ROOT}${API_PATH.GET_HALLOFFAMES}`;
export const CREATE_HALLOFFAMER = `${process.env.REACT_APP_API_ROOT}${API_PATH.CREATE_HALLOFFAMER}`;
export const DELETE_HALLOFFAMER = `${process.env.REACT_APP_API_ROOT}${API_PATH.DELETE_HALLOFFAMER}`;

/** sprint grid API */
export const GET_AVALIABLE_JIRA_SPRINT = `${process.env.REACT_APP_API_ROOT}${API_PATH.GET_AVALIABLE_JIRA_SPRINT}`;
export const CREATE_SPRINT = `${process.env.REACT_APP_API_ROOT}${API_PATH.RELEASE_SPRINT}`;

/** usermanagement API */
export const GET_USER_INFOS = `${process.env.REACT_APP_API_ROOT}${API_PATH.GET_USER_INFOS}`;
export const USER_INFO = `${process.env.REACT_APP_API_ROOT}${API_PATH.USER_INFO}`;
export const USER_ALLOCATION = `${process.env.REACT_APP_API_ROOT}${API_PATH.USER_ALLOCATION}`;
export const GET_USER_ROLES = `${process.env.REACT_APP_API_ROOT}${API_PATH.GET_USER_ROLES}`;
export const GET_USER_PERMISSIONS = `${process.env.REACT_APP_API_ROOT}${API_PATH.GET_USER_PERMISSIONS}`;
export const UPDATE_USER_ROLE_ADD = `${process.env.REACT_APP_API_ROOT}${API_PATH.UPDATE_USER_ROLE_ADD}`;
export const UPDATE_USER_ROLE_DELETE = `${process.env.REACT_APP_API_ROOT}${API_PATH.UPDATE_USER_ROLE_DELETE}`;
export const GET_PROJECT_ROLES = `${process.env.REACT_APP_API_ROOT}${API_PATH.GET_PROJECT_ROLES}`;
export const UPDATE_USER_LOGOUT = `${process.env.REACT_APP_API_ROOT}${API_PATH.UPDATE_USER_LOGOUT}`;
export const SYNC_USER_FROM_KEYCLOAK = `${process.env.REACT_APP_API_ROOT}${API_PATH.SYNC_USER_FROM_KEYCLOAK}`;

/** HALL OF FAMER API*/
export const LIKE_HALLOFFAMER = `${process.env.REACT_APP_API_ROOT}${API_PATH.LIKE_HALLOFFAMER}`;
export const APPROVE_HALLOFFAMER = `${process.env.REACT_APP_API_ROOT}${API_PATH.APPROVE_HALLOFFAMER}`;
export const GET_PROJECT = `${process.env.REACT_APP_API_ROOT}${API_PATH.PROJECT}`;
export const GET_PROJECT_SUMMARY = `${process.env.REACT_APP_API_ROOT}${API_PATH.PROJECT_SUMMAR}`;
export const GET_PROJECT_STATUS = `${process.env.REACT_APP_API_ROOT}${API_PATH.PROJECT_STATUS}`;
export const GET_ORGANIZATION_ORGANIZATIONS = `${process.env.REACT_APP_API_ROOT}${API_PATH.ORGANIZATION_ORGANIZATIONS}`;
export const PROJECT_BASICINFO = `${process.env.REACT_APP_API_ROOT}${API_PATH.PROJECT_BASICINFO}`;
export const UPDATE_PROJECT_BASICINFO = `${process.env.REACT_APP_API_ROOT}${API_PATH.UPDATE_PROJECT_BASICINFO}`;
export const PROJECT_ALL = `${process.env.REACT_APP_API_ROOT}${API_PATH.PROJECT_ALL}`;

export const GET_SCRUM_TEAMS = `${process.env.REACT_APP_API_ROOT}${API_PATH.GET_SCRUM_TEAMS}`;
export const UPDATE_SCRUM_TEAMS = `${process.env.REACT_APP_API_ROOT}${API_PATH.UPDATE_SCRUM_TEAMS}`;
export const PROJECT_MANAGERS = `${process.env.REACT_APP_API_ROOT}${API_PATH.PROJECT_MANAGERS}`;

export const GET_RULE_HISTORY = `${process.env.REACT_APP_API_ROOT}${API_PATH.GET_RULE_HISTORY}`;
export const UPDATE_RULE_HISTORY = `${process.env.REACT_APP_API_ROOT}${API_PATH.UPDATE_RULE_HISTORY}`;
export const UPDATE_PROJECT_HEALTH = `${process.env.REACT_APP_API_ROOT}${API_PATH.UPDATE_PROJECT_HEALTH}`;

/** DEV METRICS API*/
export const GET_DEVMETRICS = `${process.env.REACT_APP_API_ROOT}${API_PATH.GET_DEVMETRICS}`;
export const CREATE_DELETE_DEVMETRIC = `${process.env.REACT_APP_API_ROOT}${API_PATH.CREATE_DELETE_DEVMETRIC}`;
export const UPDATE_DEVMETRIC = `${process.env.REACT_APP_API_ROOT}${API_PATH.UPDATE_DEVMETRIC}`;

/** QA Charts APIs */
export const GET_QA_MANAGEMENT_SUMMARY = `${process.env.REACT_APP_API_ROOT}${API_PATH.QA_MANAGEMENT_SUMMARY}`;
export const GET_QA_CHART_SETTING = `${process.env.REACT_APP_API_ROOT}${API_PATH.BASE_QA_CHART_SETTING}/qaManagementChartSettings`;
export const BASE_QA_CHART_SETTING = `${process.env.REACT_APP_API_ROOT}${API_PATH.BASE_QA_CHART_SETTING}`;
export const GET_RULE_VALUE_TARGET = `${process.env.REACT_APP_API_ROOT}${API_PATH.GET_RULE_VALUE_TARGET}`;

/** Cadence Chart APIs */
export const GET_CADENCE_CHART_SUMMARY = `${process.env.REACT_APP_API_ROOT}${API_PATH.CADENCE_CHART_SUMMARY}`;
export const UpdateStoryCycleTimeThreshold = `${process.env.REACT_APP_API_ROOT}${API_PATH.UpdateStoryCycleTimeThreshold}`;
export const UpdateSprintNotes = `${process.env.REACT_APP_API_ROOT}${API_PATH.UpdateSprintNotes}`;
/** DEV CHARTS API*/
export const GET_DEVCHARTS = `${process.env.REACT_APP_API_ROOT}${API_PATH.GET_DEVCHARTS}`;

/** Dashboard  series APIs definitions */
export const REDIRECT_URI_ROOT = process.env.REACT_APP_REDIRECT_URI_LOGOUT;

export const LOGINT_REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI_LOGOUT + FE_ROUTES.HOME;
export const LOGINT_REDIRECT_URI_NEW = process.env.REACT_APP_REDIRECT_URI_LOGOUT + FE_ROUTES.LOGINJUMP;

/** keycloak series APIs definitions */
export const KEYCLOAK_TOKEN_API = process.env.REACT_APP_TOKEN_API;

/** User info APIs definitions */
export const UPDATE_USERINFO_AVATAR = `${process.env.REACT_APP_API_ROOT}${API_PATH.UPDATE_USERINFO_AVATAR}`;

/** Field Management */
export const GET_ALL_DICTTYPE = `${process.env.REACT_APP_API_ROOT}${API_PATH.GET_ALL_DICTTYPE}`;

/** Executive summary */
export const GET_PROJECT_HEALTH_BY_PROJECT_STATUS = `${process.env.REACT_APP_API_ROOT}${API_PATH.PROJECT_HEALTH}`;
export const GET_USER_TYPE_ALLOCATION = `${process.env.REACT_APP_API_ROOT}${API_PATH.USER_TYPE_ALLOCATION}`;
export const GET_ALL_SCRUM_TEAMS = `${process.env.REACT_APP_API_ROOT}${API_PATH.GET_ALL_SCRUM_TEAMS}`;
export const GET_ALL_CURRENT_RELEASE = `${process.env.REACT_APP_API_ROOT}${API_PATH.GET_ALL_CURRENT_RELEASE}`;
export const GET_ALL_PROJECT_RISKDAYS = `${process.env.REACT_APP_API_ROOT}${API_PATH.ALL_PROJECT_RISKDAYS}`;
export const GET_ALL_PROJECT_MILESTONE = `${process.env.REACT_APP_API_ROOT}${API_PATH.ALL_PROJECT_CURRENT_MILESTONE}`;
export const GET_ALL_MILESTONE_RISKDAYS = `${process.env.REACT_APP_API_ROOT}${API_PATH.ALL_MILESTONE_RISKDAYS}`;
export const GET_ALL_SPRINT_RISKDAYS = `${process.env.REACT_APP_API_ROOT}${API_PATH.ALL_SPRINT_RISKDAYS}`;
